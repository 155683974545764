import React, { useState, useEffect } from 'react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet
} from "react-router-dom";
import HOME from "./pages/home/Home.jsx";
import WORK from "./pages/work/Work.jsx";
import TERMS from "./pages/termsandconditions/Terms.jsx";
import PRIVACY from "./pages/privacyandpolicies/Privacy.jsx";
import SKILLS from "./pages/skillsandtools/Skillsandtools.jsx";
import PROJECTS from "./pages/projects/Projects.jsx";
import MILKO from "./pages/milko/Milko.jsx";
import ALTIMETRIK from "./pages/altimetrik/Altimetrik.jsx";
import MMD from './pages/mmd/Mmd.jsx';
import KAGLLEPRO from "./pages/kaglleprollc/Kaglleprollc.jsx";
import ABOUT from "./pages/about/About.jsx";
import NAVBAR from "./components/navbar/Navbar.jsx";
import FOOTER from "./components/footer/Footer.jsx";
import Fonts from "./components/10fonts/10Fonts.jsx"; // Import Fonts component

function App() {
  const [showFonts, setShowFonts] = useState(true);

  useEffect(() => {
    // Simulate loading time for fonts (adjust duration as needed)
    const timer = setTimeout(() => {
      setShowFonts(false);
      // Trigger scroll-up animation here
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 5000); // Adjust the duration in milliseconds

    return () => clearTimeout(timer);
  }, );

  const Layout = () => {
    return (
      <div className="app">
        <NAVBAR />
        <Outlet />
        <FOOTER />
      </div>
    );
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: showFonts ? <Fonts onFontsShown={() => setShowFonts(false)} /> : <Layout />, // Conditionally render Fonts or Layout
      children: [
        {
          path: "/",
          element: <HOME />,
        },
        {
          path: "/aboutme",
          element: <ABOUT />,
        },
        
        {
          path: "/work",
          element: <WORK />,
        },
        {
          path: "/termsandconditions",
          element: <TERMS />,
        },
        {
          path: "/privacy&policy",
          element: <PRIVACY />,
        },
        {
          path: "/skills",
          element: <SKILLS />,
        },
        {
          path: "/projects",
          element: <PROJECTS />,
        },
        {
          path: "/milko",
          element: <MILKO/>,
        },
        {
          path: "/altimetrik",
          element: <ALTIMETRIK />,
        },
        {
          path: "/mmd",
          element: <MMD />,
        },
        {
          path: "/kaggleprollc",
          element: <KAGLLEPRO/>,
        },
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
