import React from 'react'
import "./Aboutme.scss"
import slowmo from "../../images/slowmo.jpeg";
import aeroplane from "../../images/aeroplane (1).jpeg";
import Fourthimage from "../../images/manideep reddy logo.png"
function Aboutmecomponent() {
  return (
    <div className="Aboutmecomponent">
        <div className="container">
            <div className="first-paragraph">
                <div className="first-block">
                    <span className="paragraph">Hi,I'm Manideep R Kummmitha! 🌟 Based in Bengaluru, India, I'm a data scientist and a full-stack web developer. I thrive on the excitement of exploring new ideas and collaborating with brilliant minds to bring them to life.</span>
                </div>
                <div className="second-block">
                    <span className="paragraph">Beyond the realms of coding and data, you'll find me embracing the rhythm of life in dance classes, Watching movies ,searching for new animes to watch and posting content on youtube</span>
                </div>
            </div>
            <div className="second-line-of-images">
                <div className="fourth-image">
                    <img className="image" src={slowmo} alt=""/>
                </div>
                <div className="fifth-image">
                    <img  className="image" src={Fourthimage} alt="dragonball"/>
                </div>
                <div className="sixth-image">
                    <img className="image"  src={aeroplane} alt=""/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Aboutmecomponent