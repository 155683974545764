import React,{useState} from 'react'
import "./Work.scss"
import { Link } from 'react-router-dom';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import MILKO from "../../images/MILKO.png"
import HANUMANJI from "../../images/logo.webp"
import MMD from "../../images/mmd.jpeg"
import ALTIMETRIK from "../../images/Altimetrik.png" 
import IPS from "../../images/IPS.jpeg"
import FIVERR from "../../images/fiverrfrontend.mp4"
import GAN from "../../images/gan.png"
import Lipread from "../../images/lipread.png" 
import MULTI from "../../images/multi.jpeg"
import Chatbot from "../../images/chatbot.jpeg"
import chat from "../../images/chat.mp4"
import Food from "../../images/food order.jpeg"
import dashboard from "../../images/dashboard.jpeg"
import voice from "../../images/voice.png"
import heat from "../../images/heat.jpeg"
function Work() {
   const [hoverCoords, setHoverCoords] = useState({ x: 0, y: 0 });
   const [isHovered, setIsHovered] = useState(false);

   const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setHoverCoords({ x: clientX, y: clientY });
   };

  return (
    <div className="entire-work">
      <div className="container">

         <div className="worklist">
           <span className="title1">PROFESSIONAL WORK</span>

           <div className="listline">
             <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
                  >
                  <span className="company-text">
                     <Link className="link" >MILKO</Link>
                  </span>
                  <span className="year-text">
                     2019-2021
                  </span>
                  {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={MILKO} alt="MILKO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
            </div>
            

              <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
 
              >
               <span className="company-text">
                  <Link className="link" >ALTIMETRIK</Link>
               </span>
               <span className="year-text">
                  MAR 2022 - AUG 2022
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={ALTIMETRIK} alt="ALTIMETRIK"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}

             >
               <span className="company-text">
                  <Link className="link" >MMD | Malakonda Milk Dairy Pvt Ltd</Link>
               </span>
               <span className="year-text">
                  SEPT 2022 - MAY 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={MMD} alt="MMD"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div                  
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" >PAVANPUTRA LLC</Link>
               </span>
               <span className="year-text">
                  July 2023 - PRESENT
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={HANUMANJI} alt="MILKO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>
           </div>

         </div>

         <div className="projectlist">

          <span className="title2">ALL MY PROJECTS</span>

          <div className="datascienceprojects">
            <span className="title3">MACHINE LEARNING PROJECTS</span>

            <div className="listline">
             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/GAN.git">1.Fashion GAN: Advanced Image Generation</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={GAN} alt="gan"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/lip-buddy.git">2. Lip Buddy: End to End Lipreading Model</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={Lipread} alt="Lipread"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/MultiPose-MoveNet-.git">3.MultiPose MoveNet</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={MULTI} alt="MULTI"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/MultiPose-MoveNet-.git">4.Medical Lama Bot : AI - driven Health Assistant</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={Chatbot} alt="MILKO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>
           </div>
          </div>

          <div className="webdevelopmentprojects">
            <span className="title4">WEB DEVELOPMENT PROJECTS</span>
            <div className="listline">
             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" to="https://github.com/manideeprkummitha/fiverr-clone.git" target="_blank">FIVERR FULL STACK CLONE WEBSITE</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <video width="350" height="250" src={FIVERR} alt="FIVERR"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/food-ordering-application.git">Restaurant Food Ordering App</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={Food} alt="food"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div                 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
               <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/admin-dashborad.git">Next.js Admin Dashboard</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={dashboard} alt="MILKO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}

             >
               <span className="company-text">
                  <Link className="link" target="_blank" to="https://github.com/manideeprkummitha/chat-aplication.git">CHAT APPLICATION</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <video width="350" height="250" src={chat} alt="FIVERR"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>
           </div>
          </div>

          <div className="electronicsprojects">
            <span className="title5">COLLEGE + ELECTRONICS PROJECTS</span>
            <div className="listline">
             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" to="/">INTELLIGENT PACKAGING SYSTEM - Research Work</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={IPS} alt="IPS"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" to="/">VOICE CONTROLLED ROBOT</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={voice} alt="voice"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div                   
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}
>
               <span className="company-text">
                  <Link className="link" to="/">HEAT CONTROLLED FAN</Link>
               </span>
               <span className="topic-text">
                  TOPIC1 TOPIC2 TOPIC3
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={heat} alt="heat"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

            
           </div>
          </div>

         </div>

         <div className="button">
            <span className="skillstext">Here are all my SKILLS & CERTIFICATIONS</span>
            <button className="arrow-button">
                        <Link className="link" to="/skills">
                            <ArrowOutwardOutlinedIcon className="arrow"/>
                        </Link>
              </button>
         </div>
      </div>
    </div>
  )
}

export default Work