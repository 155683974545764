import React from 'react';
import './Home.scss';
// import Fonts from '../../components/10fonts/10Fonts';
import DESCRIBINGME from "../../components/describingme/Describingme.jsx";
import INFINTELOOP from "../../components/infinteloop/Infiniteloop.jsx"
import WORKS from "../../components/works/Works.jsx";
function Home() {

  return (
    <div>
      <DESCRIBINGME/>
      <INFINTELOOP/>
      <WORKS/>
    </div>
  );
}

export default Home;
