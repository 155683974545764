import React from 'react';
import "./Work.scss"
import { Link } from 'react-router-dom';

const Work = (props) => {
  const { subtext, company, year, workNumber, imageUrl, altText, link } = props;

  return (
    <div className="work">
      <div className="container">

        <div className="left">
          <div className="subtext">
            <span className="subtext-span">{subtext}</span>
          </div>
          <div className="company">
            <Link className="link" to={link}>
                <span className="company-span">{company}</span>
            </Link>
          </div>
          <div className="year">
            <span className="year-span">{year}</span>
          </div>
        </div>

        <div className="right">
          <div className="work-number">
            <span className='worknumber-span'>{workNumber}</span>
          </div>
          <div className="image-container">
            <img src={imageUrl} alt={altText}  className="image"/>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Work;
