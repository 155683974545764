import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll'; // Import ScrollLink and animateScroll
import './Navbar.scss';
import { GiHamburgerMenu } from 'react-icons/gi';

function Navbar() {
  const [showMediaIcons, setShowMediaIcons] = useState(false);

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="navbar">
      <div className="container">
        <div className="left">
          <div className="name-text">MRK</div>
          <div className="hidden-text">
            <Link to="/" className="mainlink">
              Manideep R Kummitha
            </Link>
          </div>
        </div>
        <div className="right">
          <button className="hamburger-menu" onClick={() => setShowMediaIcons(!showMediaIcons)}>
            <GiHamburgerMenu />
          </button>
          <ul className={showMediaIcons ? 'nav-links active' : 'nav-links'}>
            <li>
              <Link className="link" to="/work" onClick={scrollToTop}>
                WORK
              </Link>
            </li>
            <li>
              <Link className="link" to="/skills" onClick={scrollToTop}>
                SKILLS
              </Link>
            </li>
            <li>
              <Link className="link" to="/aboutme" onClick={scrollToTop}>
                ABOUT
              </Link>
            </li>
            <li>
              <ScrollLink
                to="footer" // Use the same id as your footer element
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
                className="link"
              >
                CONTACT
              </ScrollLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
