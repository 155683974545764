import React, { useState } from 'react';
import "./Describingme.scss";
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Link } from 'react-router-dom';
import ME from "../../images/manideep.jpeg"; // Ensure the image path is correct

function Describingme() {
  const [hoverCoords, setHoverCoords] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (e) => {
    const { clientX, clientY } = e;
    setHoverCoords({ x: clientX, y: clientY });
  };

  return (
    <div className="describingme">
      <div className="container">
        <div className="top1">
          <span
            className="line1"
            onMouseEnter={() => setIsHovered(true)}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => setIsHovered(false)}
          >
            I XPLORE, XPERIMENT & BUILD XPERIENCES
          </span>
          {isHovered && (
            <div
              className="hover-image"
              style={{
                left: `${hoverCoords.x}px`,
                top: `${hoverCoords.y}px`,
                position: 'fixed', // Or 'absolute' if you want it to be within the container
              }}
            >
              <img src={ME} alt="MANIDEEP"  /> {/* Adjust size as needed */}
            </div>
          )}
        </div>
        <div className="bottom1">
          <div className="left">
            <div className="svg">
                <svg width="233" height="121" viewBox="0 0 233 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                            d="M1 1C24.1778 10.741 47.0701 20.9782 69.1085 33.1274C92.4583 45.9995 115.893 61.3548 132.06 83.0048C137.065 89.7066 142.941 98.5964 142.819 107.411C142.742 112.934 135.615 117.475 131.082 118.815C123.229 121.138 113.118 119.82 105.652 116.818C99.8574 114.489 89.8256 108.254 89.8256 100.977C89.8256 88.2399 117.102 86.4402 124.369 85.6229C160.566 81.5519 197.278 87.3847 232 97.3822"
                            stroke="#CE1B1B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            style={{ strokeDashoffset: "0px" }}
                            />
                </svg>
            </div>
          </div>
          <div className="right">
            <div className="top">
              <span className="para1">
                As a creative professional, I specialize in full-stack web development and data science.
                I create websites that not only look stunning 
                but also deliver exceptional user experiences. Let's collaborate to enhance your online
                presence and unlock the potential of 
                data-driven insights to grow your digital footprint.
              </span>
            </div>
            <div className="mid">
              <span className="para2">
                I make boring stuff INTERESTING!!!!!
              </span>
            </div>
            <div className="bottom">
              <span className="para3">
                ABOUT ME
              </span>
              <button className="arrow-button">
                <Link className="link" to="/aboutme">
                  <ArrowOutwardOutlinedIcon className="arrow"/>
                </Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Describingme;
