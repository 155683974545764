import React from 'react'
import "./Milko.scss"
function Milko() {
  return (
    <div className="milko">
       <div className="container">
         <div className="text">
            {/* <div className="scrolling"> */}
              <div className="scrolling-text scroll-right">
                MILKO MILKO MILKO
              </div>
              <div className="scrolling-text scroll-left">
                MILKO MILKO MILKO 
              </div>
            {/* </div> */}
         </div>
         <div className="overview">
          <div className="company-overview">
            <div className="company">
              <span className="company-name">Kaggle Pro LLC</span>
            </div>
            <div className="job-details">
              <div className="company-info">
                <p>Pavanputra • 2023</p>
                <p className="company-description">To job seekers, ZipRecruiter had long been known as a job search engine. While our Suggested Jobs page received a lot of traffic, users often complained that they didn’t know why they were being shown certain jobs. Our engineering team also had issues with the page, adding that it was complex and difficult to run tests on. How could we redesign the Suggested Jobs page to give users a stronger sense of clarity and control, while also reducing engineering complexity?</p>
              </div>
              <div className="roles-responsibilities">
                <div className="role-info">
                  <div className="role">ROLE</div>
                  <div className="designation">Lead Product Designer</div>
                </div>
                <div className="responsibilities-info">
                  <div className="responsibilities">RESPONSIBILITIES</div>
                  <ul>
                    <li>UX/UI</li>
                    <li>System Design</li>
                    <li>Product Strategy</li>
                    <li>Prototyping</li>
                  </ul>
                </div>
              </div>
              <div className="team-info">
                <div className="team">TEAM</div>
                <ul>
                  <li>Nishok Chetty, Product Manager</li>
                  <li>Gerald Burns, Engineer</li>
                  <li>James Messrie, Engineer</li>
                  <li>Sandra Suttitatana, Engineer</li>
                  <li>Mrinalini Garg, Analytics</li>
                </ul>
              </div>
            </div>
            <div className="problem-statement">
              <span className="problem"> Problem</span>
              <span className='statement'>Because our Suggested Jobs page shows mixed job content based on search and application activity, a stray look into a dream job or a search for a friend could instantly dilute a user’s page with seemingly random results.</span>
            </div>
            {/* Image */}
            <div className="our-vision">
              <span className="vision">Our Vision</span>
              <span className='statement'>For ZipRecruiter to become a leader in job matching, we needed to help job seekers quickly find jobs they love. We had to ensure suggested job content was easily browsable and easily discoverable, and the Suggested Jobs page was engineered flexibly for painless future iteration.</span>
            </div>
            <div className="features-block">
              <span className="feature">Feature 1</span>
              <span className="feature-name">Grouping Content</span>
              <p className="feature-details">By grouping content into horizontal carousels, users can dive deeper into groupings that interest them and easily skip those that don’t. With seemingly endless group types to test in the future, I made sure the group headers were versatile enough to accommodate whatever we wanted. I used subtle cues to differentiate different group types—a square logo to accompany groups based on a specific job and an icon in a circle to signify a broader group. </p>
            </div>
            <div className="feature-image-1">
              {/* <img src="dummy-image-1.jpg" alt="Dummy Image 1" /> */}
            </div>
            <div className="features-block">
              <span className="feature">Feature 1</span>
              <span className="feature-name">Grouping Content</span>
              <p className="feature-details">By grouping content into horizontal carousels, users can dive deeper into groupings that interest them and easily skip those that don’t. With seemingly endless group types to test in the future, I made sure the group headers were versatile enough to accommodate whatever we wanted. I used subtle cues to differentiate different group types—a square logo to accompany groups based on a specific job and an icon in a circle to signify a broader group. </p>
            </div>
            <div className="feature-image-2">
              {/* <img src="dummy-image-2.jpg" alt="Dummy Image 2" /> */}
            </div>
            <div className="features-block">
              <span className="feature">Feature 1</span>
              <span className="feature-name">Grouping Content</span>
              <p className="feature-details">By grouping content into horizontal carousels, users can dive deeper into groupings that interest them and easily skip those that don’t. With seemingly endless group types to test in the future, I made sure the group headers were versatile enough to accommodate whatever we wanted. I used subtle cues to differentiate different group types—a square logo to accompany groups based on a specific job and an icon in a circle to signify a broader group. </p>
            </div>
            <div className="feature-image-3">
              {/* <img src="dummy-image-3.jpg" alt="Dummy Image 3" /> */}
            </div>
            
            <div className="summary">
            <span className="summary-title">Summary</span>
              <p>After working closely with engineering to bring the designs to life, we launched the test and crossed our fingers. At the project’s inception, we documented two primary indicators of success; higher engagement and improved satisfaction. Fortunately, our users found the experience more engaging and satisfying than ever.</p>
              <p>After working closely with engineering to bring the designs to life, we launched the test and crossed our fingers. At the project’s inception, we documented two primary indicators of success; higher engagement and improved satisfaction. Fortunately, our users found the experience more engaging and satisfying than ever.</p>
              <p>After working closely with engineering to bring the designs to life, we launched the test and crossed our fingers. At the project’s inception, we documented two primary indicators of success; higher engagement and improved satisfaction. Fortunately, our users found the experience more engaging and satisfying than ever.</p>
            </div>
          </div>
        </div>
       </div>  
    </div> 
     )
}

export default Milko