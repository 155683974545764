import React from 'react'
import "./Works.scss"
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Link } from 'react-router-dom';
import WORK from "../work/Work.jsx";
import MILKO from "../../images/MILKO.png"
import HANUMANJI from "../../images/logo.webp"
import MMD from "../../images/mmd.jpeg"
import ALTIMETRIK from "../../images/Altimetrik.png" 
function Works() {
  return (
    <div className="works">
        <div className="container">

            <span className="maintitle">SELECTED CASES</span>

            <div className="workdescription">
                <span className="subtext">
                    "Explore my latest and greatest projects below – each one
                    is a unique journey in creativity. For an even more immersive experience, dive into my full portfolio
                    and discover even more captivating work."
                </span>
                <div className="button">
                   <span className="direct">ALL MY WORK & PROJECTS</span>
                   <button className="arrow-button">
                        <Link className="link" to="/work">
                            <ArrowOutwardOutlinedIcon className="arrow"/>
                        </Link>
                    </button>
                </div>
            </div>

            <div className="every-main-work">
                <WORK
                    subtext="Co-Founder | CEO"
                    company="MILKO"
                    year="2019 - 2021"
                    workNumber="01"
                    imageUrl={MILKO}
                    altText="MILKO"
                    // link="/milko"
                    className="eachproject"
                />

                <WORK
                    subtext="DEVOPS PROJECT INTERN"
                    company="ALTIMETRIK"
                    year="MAR 2022 - AUG 2022"
                    workNumber="02"
                    imageUrl={ALTIMETRIK}
                    altText="ALTIMETRK"
                    // link="/altimetrik"
                    className="eachproject"
                />

               <WORK
                    subtext="MALAKONDA MILK DAIRY PVT LTD | SDE INTERN"
                    company="MMD"
                    year="SEPT 2022 - MAY 2023"
                    workNumber="03"
                    imageUrl={MMD}
                    altText="MMD"
                    // link="/mmd"
                    className="eachproject"
                />

                <WORK
                    subtext="DATA SCIENTIST & WEB DEVELOPER"
                    company="PAVANPUTRA LLC"
                    year="2023"
                    workNumber="04"
                    imageUrl={HANUMANJI}
                    altText="PAVANPUTRA LLC"
                    // link="/kaggleprollc"
                    className="eachproject"
                />
            </div>
        </div>
    </div>
  )
}

export default Works