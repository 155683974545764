import React,{useState} from 'react'
import "./Skillsandtools.scss"
import { Link } from 'react-router-dom';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import ALandML from "../../images/ai and ml nano course.png";
import DATASCIENCE from "../../images/data science.png";
import DBMS from "../../images/dbms.png";
import SQL from "../../images/sql .png";
import DSA from "../../images/das in java course.png";
import CPP from "../../images/cpp .png";
import JAVANANO from "../../images/java nano.png";
import PYTHONNANO from "../../images/python nano degree.png";
import UIUX from "../../images/ui ux workshop.png";
import AWS from "../../images/aws beanstalk.png";
import JAVADSP from "../../images/java.jpg";
import WEB from "../../images/webprogramming.jpg";
import LINUX from "../../images/linux.jpg";
function Skillsandtools() {

  const skills = [
    'C',
    'C++',
    'Python',
    'Java',
    'MongoDB',
    'Express.js',
    'React.js',
    'Node.js',
    'Javascript',
    'HTML',
    'CSS',
    'Microservices',
    'API',
    'Flask',
    'AWS Basics',
    'Docker',
    'Kubernetes',
    'SQL',
    'Linux',
    'Unix',
    'Matlab',
    'Figma (UI/UX)',
  ];

  const [hoverCoords, setHoverCoords] = useState({ x: 0, y: 0 });
   const [isHovered, setIsHovered] = useState(false);

   const handleMouseMove = (e) => {
      const { clientX, clientY } = e;
      setHoverCoords({ x: clientX, y: clientY });
   };

  return (
    <div className="skillsandtools">
      <div className="container">
        <div className="skills-tools">
          <span className="textline1">My Skill-Set & Tools i use</span>
           <table className="grid-container">
            <tbody>
              {Array(5)
                .fill(null)
                .map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array(4)
                      .fill(null)
                      .map((_, colIndex) => (
                        <td key={colIndex}>
                          {skills[rowIndex * 4 + colIndex]}
                        </td>
                      ))}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="certifications">
          <span className="textline2">All my CERTIFICATIONS</span>
          <div className="listline">
             <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
             >
               <span className="company-text">
                  <Link className="link" >AI & ML Nanodegree</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={ALandML} alt="AIandML"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

            <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
              >
               <span className="company-text">
                  <Link className="link">Data Science Certification</Link>
               </span>
               <span className="year-text">
               Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={DATASCIENCE} alt="DATASCIENCE"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
             >
               <span className="company-text">
                  <Link className="link">DBMS</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={DBMS} alt="DBMS"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div                   
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
>
               <span className="company-text">
                  <Link className="link" >SQL</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={SQL} alt="SQL"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
              >
               <span className="company-text">
                  <Link className="link" >DSA in java Course Certification</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={DSA} alt="DSA"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             

              <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
              >
               <span className="company-text">
                  <Link className="link" >CPP Nano Degree</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={CPP} alt="CPP"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>


              <div                   
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
>
               <span className="company-text">
                  <Link className="link" >Java Nano Degree</Link>
               </span>
               <span className="year-text">
                  Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={JAVANANO} alt="JAVANANO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
              >
               <span className="company-text">
                  <Link className="link" >Python Nano Degree</Link>
               </span>
               <span className="year-text">
                     Prepinsta | 14th June 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={PYTHONNANO} alt="PYTHONNANO"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

               <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
               >
               <span className="company-text">
                  <Link className="link" >UX Workshop</Link>
               </span>
               <span className="year-text">
                  Growth School | May 2023
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={UIUX} alt="UIUX"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

               <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
 
               >
               <span className="company-text">
                  <Link className="link" >AWS Beanstalk</Link>
               </span>
               <span className="year-text">
                 Udemy | 21st April 2022
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={AWS} alt="AWS"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

              <div
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
              >
               <span className="company-text">
                  <Link className="link" >Java Programming ( Skill Development Program )</Link>
               </span>
               <span className="year-text">
                Reva University | 14/09/2019 - 17/09/2019
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={JAVADSP} alt="JAVADSP"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div 
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)}  
             >
               <span className="company-text">
                  <Link className="link" >Fundamentals of Web Programming ( Skil Development Program )</Link>
               </span>
               <span className="year-text">
                  Reva University | 15/02/2019 - 18/02/2019
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={WEB} alt="WEB"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>

             <div                   
                  className="fullline"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={() => setIsHovered(false)} 
>
               <span className="company-text">
                  <Link className="link" >Fundamentals of LINUX OS ( Skil Development Program )</Link>
               </span>
               <span className="year-text">
                  Reva University | 24/10/2018 - 27/10/2018
               </span>
               {isHovered && (
                     <div
                     className="hover-image"
                     style={{
                        left: `${hoverCoords.x}px`,
                        top: `${hoverCoords.y}px`,
                        position: 'fixed', // Or 'absolute' if you want it to be within the container
                     }}
                     >
                     <img src={LINUX} alt="LINUX"  /> {/* Adjust size as needed */}
                     </div>
                  )}
             </div>
           </div>
        </div>
        <div className="button">
            <span className="skills-text">Here are all my WORK & PROJECTS</span>
            <button className="arrow-button">
                        <Link className="link" to="/work">
                            <ArrowOutwardOutlinedIcon className="arrow"/>
                        </Link>
              </button>
        </div>
      </div>
    </div>
  )
}

export default Skillsandtools