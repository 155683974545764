import React from 'react'
import "./About.scss";
import INFINTELOOP from "../../components/infinteloop/Infiniteloop.jsx";
import Firstimage from "../../images/gipsy avenger.jpg";
import Secondimage from "../../images/dragonball.jpg";
import Thirdimage from "../../images/saber athena.jpg";
import Aboutmecomponent from '../../components/aboutme-component/Aboutme.jsx';
function About() {
  return (
    <div className="aboutme">
       <div className="container">
        <div className="redblock">
          <h2 className="subtext">I build & make cool INTRESTING stufff!!</h2>
          <h2 className="line1">ABOUT ME</h2>
          <h2 className="line2">ABOUT ME</h2>
          <h2 className="line3">ABOUT ME</h2>
          <div className="first-line-of-images">
            <div className="first-image">
              <img className="image" src={Firstimage} alt=""/>
            </div>
            <div className="second-image">
              <img  className="image" src={Secondimage} alt="dragonball"/>
            </div>
            <div className="third-image">
              <img className="image"  src={Thirdimage} alt=""/>
            </div>
          </div>
        </div>
        <INFINTELOOP/>
        <Aboutmecomponent/>

       </div>
    </div>
  )
}

export default About