import React from 'react';
import "./Infiniteloop.scss";

function Infiniteloop() {
  // Array of words to be scrolled
  const words = ['XPLORE ', 'XPERIMENT', 'XPERIENCE'];

  // Calculate how many times to repeat the array
  // This should be enough to always fill the screen
  // Adjust the number based on the size of your words and the screen width
  const repeatCount = 20;

  return (
    <div className="infiniteloop">
      <div className="container">
        {Array.from({ length: repeatCount }, () => words).flat().map((word, index) => (
          <span key={index} className="text">{word}</span>
        ))}
      </div>
    </div>
  );
}

export default Infiniteloop;
