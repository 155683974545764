import React from 'react'
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import "./Footer.scss";
import {Link} from "react-router-dom"
function Footer() {
  return (
    <div className="footer" id="#footer">
      <div className="container">
        <div className="top">
          <div className="left"> 
             <span>DROP A MESSAGE <span className="extra">!!!!!</span></span>
          </div>
          <div className="right">
            <div className="container1">
              <span>Get in Touch</span>
            </div>
            <div className="container2">
              <span>Seeking a creative force to make your ideas 
              come to life? Look no further. I'm here to transform 
              concepts into captivating designs and Products. 
              Let's create something extraordinary together.</span>
            </div>
            <div className="container3">
             <span>CONTACT</span>
             <button className="arrow-button">
                <Link className="link" lassName="link" to={`mailto:maanideeprkummiitha@gmail.com`} target="_blank">
                    <ArrowOutwardOutlinedIcon className="arrow"/>
                </Link>             
            </button>
            </div>
          </div>
        </div>

        <div className="mid">
          <div className="row-credentials">
            <Link className="link">Instagram</Link>
            <Link className="link" target="_blank" to="https://www.youtube.com/channel/UCybpecwljAUbvtTpe4BXTOQ">Youtube</Link>
          </div>
          <div className="row-credentials">
            <Link className="link" target="_blank" to="https://medium.com/@maanideeprkummiitha">medium</Link>
            <Link className="link" target="_blank" to="https://github.com/manideeprkummitha">Github</Link>
          </div>
           <div className="row-credentials">
            <Link className="link" target="_blank" to="https://www.linkedin.com/in/manideep-r-kummitha-b98a27271?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BH9%2BqNGA8R2ufVusVQoJjSQ%3D%3D">Linkedin</Link>
            <Link className="link">Twitter</Link>
          </div>
          <div className="row-credentials">
            <a className="link" href="mailto:maanideeprkummiitha@gmail.com">maanideeprkummiitha@gmail.com</a>
            <a className="link" href="mailto:maanideeprkummiitha@gmail.com">Xplorer's Lab</a>
          </div>
        </div>

        <div className="bottom">
          <Link className="link">TERMS & CONDITIONS</Link>
          <Link className="link">PRIVACY POLICY</Link>
        </div>
      </div>
    </div>
  )
}

export default Footer