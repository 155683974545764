import React, { useEffect, useState } from 'react';
import './10Fonts.scss';

const fontStyles = [
  "font-family: Arial, sans-serif",
  "font-family: Helvetica, sans-serif",
  "font-family: 'Times New Roman', serif",
  "font-family: Georgia, serif",
  "font-family: Verdana, sans-serif",
  "font-family: 'Courier New', monospace",
  "font-family: Impact, sans-serif",
  "font-family: 'Comic Sans MS', cursive",
  "font-family: Palatino, serif",
  "font-family: Tahoma, sans-serif"
];

function Fonts({ onFontsShown }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex < fontStyles.length - 1) {
          return prevIndex + 1;
        } else {
          onFontsShown();

          // Scroll to the next page here
          window.scrollTo({ top: window.innerHeight, behavior: 'smooth' });

          return prevIndex;
        }
      });
      console.log('Index changed:', currentIndex); // Add this line for debugging
    }, 200); // Adjust the time between font style changes (in milliseconds)

    return () => clearTimeout(timer);
  }, [currentIndex, onFontsShown]);

  console.log('Render:', currentIndex); // Add this line for debugging

  return (
    <div className="font">
      <div className="font-style" style={{ cssText: fontStyles[currentIndex] }}>
        MANIDEEP.R.KUMMITHA
      </div>
    </div>
  );
}

export default Fonts;
